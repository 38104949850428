import AppDispatcher from "../../../flux/dispatcher/dispatcher";
import Constant from "../constant/Constants";
import restClient from "../../MainFrame/action/APIClient";
import LogoutConstant from "../../MainFrame/constants/Constants";

var LineChartAction = function () {};

LineChartAction.prototype = {
	loadData: function (data) {
		data["filters"]["insightFilters"] = {
			metrics: [
				"ACTIONS_DRIVING_DIRECTIONS",
				"ACTIONS_PHONE",
				"ACTIONS_WEBSITE",
			],
		};
		restClient.getLocationInsightHistogram(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error,
					});
				} else {
					AppDispatcher.dispatch({
						actionType: Constant.GET_LINE_CHART,
						data: error,
					});
				}
			} else if (response != null) {
				response["status"] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.GET_LINE_CHART,
					data: response,
				});
			}
		});
	},
};

export default new LineChartAction();
