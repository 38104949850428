import React, { useEffect, useRef, useState } from "react";

import styles from "./IconTooltip.module.scss";

var languageFile = require("../../../../public/languages/Translation");

export const IconTooltip = (props) => {
	const tooltipRef = useRef();
	const [positionX, setPositionX] = useState(props.positionX);
	const [positionY, setPositionY] = useState(props.positionY);
	useEffect(() => {
		const edges = {
			right:
				Math.abs(props.positionX - props.canvasWidth) <
				tooltipRef.current.offsetWidth,
			bottom:
				Math.abs(props.positionY - props.canvasHeight) <
				tooltipRef.current.offsetHeight,
		};

		setPositionX(
			edges.right
				? props.positionX - tooltipRef.current.offsetWidth
				: props.positionX,
		);
		setPositionY(
			edges.bottom
				? props.positionY - tooltipRef.current.offsetHeight
				: props.positionY,
		);
	}, [tooltipRef.current, props]);
	return (
		<div
			className={styles.iconTooltip}
			ref={tooltipRef}
			style={{
				transform: `translate(${positionX}px, ${positionY}px)`,
			}}>
			{props.data.storeCode && <p>{props.data.storeCode}</p>}
			{props.data.locationName && <p>{props.data.locationName}</p>}
			{props.data.rating &&  (
				<p>{`${props.data.rating} ${languageFile[localStorage.getItem('language')]['22191']}`}</p>
			)}
			{ props.data.reviewer && (
				<p>{`${languageFile[localStorage.getItem('language')]['30933']}: ${props.data.reviewer}`}</p>
			)}
		</div>
	);
};
