import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import { defaultGraphDataFormating } from "../../utils/stackedbarChartDataFormatter";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Amchartmenu } from "../../routers/Dashboard/children/ChartDownload";
import _ from "lodash";
let barLineChart;
var languageFile = require("../../../public/languages/Translation");
am4core.useTheme(am4themes_animated);

class StackedBarLineComposedChart extends Component {
	componentDidMount() {
		this.ChartRendering();
	}

	ChartRendering() {
		var self = this;
		var barLineChart = am4core.create(this.props.id, am4charts.XYChart);
		barLineChart.numberFormatter.numberFormat = "#.##s";
		barLineChart.maskBullets = false;
		barLineChart.data = defaultGraphDataFormating(
			[
				"1",
				"2",
				"3",
				"4",
				"5",
				this.props.secondaryType ? this.props.secondaryType : "avg",
			],
			this.props.chartData,
			this.props.secondaryType,
		);
		var categoryAxis = barLineChart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "date";
		// categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 80;
		categoryAxis.tooltip.disabled = true;
		categoryAxis.renderer.grid.template.disabled = true;
		categoryAxis.renderer.line.strokeOpacity = 1;
		categoryAxis.renderer.line.strokeWidth = 1;
		categoryAxis.renderer.line.stroke = "#8c8c8c";
		categoryAxis.renderer.labels.template.enabled = true;
		// let label = categoryAxis.renderer.labels.template;
		// label.tooltipText = "{category}";

		//axis tick
		categoryAxis.renderer.ticks.template.disabled = false;
		categoryAxis.renderer.ticks.template.strokeOpacity = 1;
		categoryAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
		categoryAxis.renderer.ticks.template.strokeWidth = 2;
		categoryAxis.renderer.ticks.template.length = 7;

		var valueAxis = barLineChart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.renderer.grid.template.disabled = true;
		valueAxis.renderer.minWidth = 50;
		valueAxis.min = 0;
		{
			this.props.showSecondaryGraph == false && (valueAxis.max = 100);
			valueAxis.strictMinMax = true;
		}
		valueAxis.cursorTooltipEnabled = false;
		valueAxis.renderer.line.strokeOpacity = 1;
		valueAxis.renderer.line.strokeWidth = 2;
		valueAxis.renderer.line.stroke = "#8c8c8c";
		valueAxis.renderer.opposite = false;
		valueAxis.extraMax = 0.1;
		valueAxis.calculateTotals = true;

		//axis tick
		valueAxis.renderer.ticks.template.disabled = false;
		valueAxis.renderer.ticks.template.strokeOpacity = 1;
		valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
		valueAxis.renderer.ticks.template.strokeWidth = 2;
		valueAxis.renderer.ticks.template.length = 7;

		// Create series

		function createSeries(field, name, color) {
			var series = barLineChart.series.push(new am4charts.ColumnSeries());
			// series.sequencedInterpolation = true;
			series.dataFields.valueY = field;
			series.dataFields.categoryX = "date";
			series.tooltipText = "{name}: {valueY}[/]";
			// series.columns.template.strokeWidth = 0;
			series.stacked = true;
			series.fill = am4core.color(color);
			series.stroke = am4core.color(color);
			series.name = name;
			series.columns.template.events.on(
				"hit",
				function (ev) {
					let graphFields =
						ev.target.dataItem.component.tooltipDataItem.dataContext;
					self.props.stackedBarChartData(graphFields);
				},
				this,
			);
		}
		createSeries(
			"1 star",
			languageFile[localStorage.getItem("language")]["10010"],
			"#FF5722",
		);
		createSeries(
			"2 star",
			languageFile[localStorage.getItem("language")]["10013"],
			"#FF9800",
		);
		createSeries(
			"3 star",
			languageFile[localStorage.getItem("language")]["10016"],
			"#ababab",
		);
		createSeries(
			"4 star",
			languageFile[localStorage.getItem("language")]["10019"],
			"#03A9F4",
		);
		createSeries(
			"5 star",
			languageFile[localStorage.getItem("language")]["10022"],
			"#4CAF50",
		);
		// Create series for total
		var totalSeries = barLineChart.series.push(new am4charts.ColumnSeries());
		totalSeries.dataFields.valueY = "none";
		totalSeries.dataFields.categoryX = "date";
		totalSeries.stacked = true;
		totalSeries.hiddenInLegend = true;
		totalSeries.columns.template.strokeOpacity = 0;
		totalSeries.tooltipText = `${languageFile[localStorage.getItem("language")]["13935"]}: {valueY.total}[/]`;
		totalSeries.tooltip.getFillFromObject = false;
		totalSeries.tooltip.background.fill = am4core.color("#FF99CC");
		totalSeries.tooltip.autoTextColor = false;
		totalSeries.tooltip.label.fill = am4core.color("#00000");
		// let totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
		// totalBullet.dy = -20;
		// totalBullet.label.text = "{valueY.total}";
		// totalBullet.label.hideOversized = false;
		// totalBullet.label.fontSize = 10;
		// totalBullet.label.background.fill = totalSeries.stroke;
		// totalBullet.label.background.fillOpacity = 0.2;
		// totalBullet.label.padding(5, 10, 5, 10);
		if (this.props.showSecondaryGraph !== false) {
			var avgValueAxis = barLineChart.yAxes.push(new am4charts.ValueAxis());
			avgValueAxis.renderer.line.strokeOpacity = 1;
			avgValueAxis.renderer.line.strokeWidth = 2;
			avgValueAxis.renderer.line.stroke = "#8c8c8c";
			avgValueAxis.renderer.labels.template.enabled = true;

			avgValueAxis.renderer.ticks.template.disabled = false;
			avgValueAxis.renderer.ticks.template.strokeOpacity = 1;
			avgValueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
			avgValueAxis.renderer.ticks.template.strokeWidth = 2;
			avgValueAxis.renderer.ticks.template.length = 7;

			avgValueAxis.renderer.opposite = true;
			avgValueAxis.renderer.grid.template.disabled = true;
			avgValueAxis.cursorTooltipEnabled = false;
			avgValueAxis.min = 0;
			{
				this.props.secondaryType === "responseRatioPercentage" &&
					(avgValueAxis.max = 100);
			}

			var avgValueSeries = barLineChart.series.push(new am4charts.LineSeries());
			avgValueSeries.dataFields.valueY = "avg";
			avgValueSeries.dataFields.categoryX = "date";
			avgValueSeries.yAxis = avgValueAxis;
			avgValueSeries.name = this.props.secondaryType
				? languageFile[localStorage.getItem("language")]["30818"]
				: languageFile[localStorage.getItem("language")]["22201"];
			avgValueSeries.tooltipText = this.props.secondaryType
				? '{name}: {valueY.formatNumber("#,###.0")}[/]%'
				: '{name}: {valueY.formatNumber("#,###.0")}[/]';
			// avgValueSeries.bullets.push(new am4charts.CircleBullet());
			avgValueSeries.strokeWidth = 2;
			//for spline barLineChart
			avgValueSeries.tensionX = 0.87;

			avgValueSeries.stroke = am4core.color("#08589E");
			avgValueSeries.fill = am4core.color("#08589E");
			// var bullet = avgValueSeries.bullets.push(new am4charts.Bullet());
			// bullet.fill = am4core.color("#8884D8"); // tooltips grab fill from parent by default
			// bullet.radius= 1;
			// bullet.strokeWidth = 1;
			// bullet.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]"
			// var circle = bullet.createChild(am4core.Circle);
			// circle.radius=3;
			var circleBullet = avgValueSeries.bullets.push(
				new am4charts.CircleBullet(),
			);
			// circleBullet.circle.maxHeight = 1;
			// circleBullet.circle.maxWidth = 1;
			// circleBullet.circle.outerHeight = '1';
			// circleBullet.circle.outerWidth = '1';
			circleBullet.circle.radius = 3;
			// circleBullet.circle.strokeWidth = 1;
			// circleBullet.circle.width = '1';
			// circleBullet.circle.height = '1';
			// circleBullet.circle.innerHeight = '1';
			// circleBullet.circle.innerHeight = '1';

			// circleBullet.circle.stroke=2;
			// circle.fill = am4core.color("#8884D8");
		}
		// Cursor
		barLineChart.cursor = new am4charts.XYCursor();
		// barLineChart.cursor.lineX.stroke = am4core.color("#dadfe4");
		// barLineChart.cursor.lineX.strokeWidth = 2;
		// barLineChart.cursor.lineX.strokeOpacity = 0.4;
		// barLineChart.cursor.lineX.strokeDasharray = "";

		// barLineChart.cursor.lineY.stroke = am4core.color("#dadfe4");
		// barLineChart.cursor.lineY.strokeWidth = 2;
		// barLineChart.cursor.lineY.strokeOpacity = 0.4;
		// barLineChart.cursor.lineY.strokeDasharray = "";

		//download
		if (this.props.isShowDownload) {
			barLineChart.exporting.menu = new am4core.ExportMenu();
			barLineChart.exporting.menu.items = _.cloneDeep(Amchartmenu);
			barLineChart.exporting.dataFields = {
				date: "Date",
				"1 star": "1 star",
				"2 star": "2 star",
				"3 star": "3 star",
				"4 star": "4 star",
				"5 star": "5 star",
				avg:
					this.props.showSecondaryGraph !== false
						? this.props.secondaryType
							? "Response Ratio"
							: "avg"
						: null,
			};
			barLineChart.exporting.timeoutDelay = 8000;
			barLineChart.exporting.filePrefix = this.props.downloadChart;
		}
		// Add legend
		barLineChart.legend = new am4charts.Legend();
		// barLineChart.logo.disabled = true;
		this.barLineChart = barLineChart;
	}

	componentDidUpdate(oldProps) {
		if (oldProps.chartData !== this.props.chartData) {
			this.barLineChart.data = defaultGraphDataFormating(
				[
					"1",
					"2",
					"3",
					"4",
					"5",
					this.props.secondaryType ? this.props.secondaryType : "avg",
				],
				this.props.chartData,
				this.props.secondaryType,
			);
		}
	}
	componentWillUnmount() {
		if (this.barLineChart) {
			this.barLineChart.dispose();
		}
	}
	render() {
		return (
			<div
				id={this.props.id}
				style={{ width: "100%", height: "100%" }}></div>
		);
	}
}
export default StackedBarLineComposedChart;
