import React from "react";
import styles from "./Wrapper.module.scss";
import RatingChart from "../ResponseChart";
import { Empty, Spin } from "antd";

const CommentsGraphWrapper = ({ children, data, label, desc, loader, width }) => {
	return (
		<div className={styles.card} style={{width: width}}>
			<h3>{label}</h3>
			<p>{desc}</p>
			{loader ? (
				<div className={styles.loaderBlock}>
					<div class="graph-loader">
						<div class="loader"></div>
					</div>
				</div>
			) : (
				<div className={styles.sourceList}>
					{data.length > 0 ? (
						children
					) : (
						<div className={styles.noData}>
							<Empty />
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default CommentsGraphWrapper;
