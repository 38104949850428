var languageFile = require("../../../public/languages/Translation");


export const TOOLTIP_CONSTANTS = {
	METRIC_TREND_TOOLTIP: (trend, metricField, dateInterval) => {
			const language = localStorage.getItem("language");
			const keys = {
				Decrease: "30829",
				Increase: "30830",
				Default: "30831",
			};
			const translation = languageFile[language][keys[trend] || keys.Default];
			return translation
				.replace("{x data}", metricField)
				.replace("{xx}", dateInterval);
		},
	TOTAL_VIEWS_INFO: languageFile[localStorage.getItem("language")]["30894"],
	MAP_VIEWS_INFO:   languageFile[localStorage.getItem("language")]["30895"],
	SEARCH_VIEWS_INFO: languageFile[localStorage.getItem("language")]["30896"],
	ACTIONS_INFO: languageFile[localStorage.getItem("language")]["30897"],
	CONVERSION_RATE_INFO: languageFile[localStorage.getItem("language")]["30898"]
};

export const METRIC_CARD_CONSTANTS = {
	TOTAL_VIEWS: languageFile[localStorage.getItem("language")]["30892"],
	MAP_VIEWS: languageFile[localStorage.getItem("language")]["2078"],
	SEARCH_VIEWS: languageFile[localStorage.getItem("language")]["2077"],
	ACTIONS: languageFile[localStorage.getItem("language")]["21211"],
	CONV_RATE: languageFile[localStorage.getItem("language")]["30893"],
};
