import AppDispatcher from "../../../flux/dispatcher/dispatcher";
// import AppDispatcher from 'dispatcher/dispatcher';
import Constant from "../constant/Constants";
import restClient from "../../MainFrame/action/APIClient";
import LogoutConstant from "../../MainFrame/constants/Constants";
var _ = require("lodash");

var GetPerformanceOverviewAction = function () {};

GetPerformanceOverviewAction.prototype = {
	getPerformanceOverview: function (data) {
		restClient.getPerformanceOverview(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error,
					});
				} else {
					AppDispatcher.dispatch({
						actionType: Constant.GET_PERFORMANCE_OVERVIEW,
						data: error,
					});
				}
			} else if (response != null) {
				response["status"] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.GET_PERFORMANCE_OVERVIEW,
					data: response,
				});
			}
		});
	},
};

export default new GetPerformanceOverviewAction();
