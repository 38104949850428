import React from "react";
import {
	ComposedChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
} from "recharts";

export const ComposedBarChart = ({ data, language }) => {
	return (
		<div style={{ width: "96%", height: "100%", maxWidth: "1000px" }}>
			<ResponsiveContainer
				width={"100%"}
				height={"100%"}>
				<ComposedChart
					width={"100%"}
					height={400}
					data={data}
					margin={{
						top: 20,
						right: 20,
						bottom: 20,
						left: 20,
					}}>
					<XAxis dataKey="dayOfWeek" />
					<YAxis
						yAxisId={0}
						orientation="left"
					/>
					<Tooltip />

					<Bar
						dataKey={language}
						yAxisId={0}
						barSize={400}
						fill="#08589e"
					/>
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	);
};
