import React, { Children } from "react";
import styles from "./Wrapper.module.scss";
import { Empty, Spin } from "antd";
import ReviewReviewsChart from "../ReviewReviewsChart";

const ReviewReviewChartWrapper = ({ data, desc, label, loader }) => (
	<div className={styles.card}>
		<h3>{label}</h3>
		{loader ? (
			<div className={styles.loaderBlock}>
				<div class="graph-loader">
					<div class="loader"></div>
				</div>
			</div>
		) : data.length > 0 ? (
			<>
				<p>{desc}</p>
				<div className={styles.sourceList}>
					<ReviewReviewsChart data={data} />
				</div>
			</>
		) : (
			<div className={styles.noData}>
				<Empty />
			</div>
		)}
	</div>
);

export default ReviewReviewChartWrapper;
