import React from "react";
import ReviewSourceItem from "../ReviewSourceItem";
import styles from "./ReviewSourcesCard.module.scss";
import { Empty, Spin } from "antd";


var languageFile = require("../../../public/languages/Translation");

const ReviewSourcesCard = ({ sources, loader,width }) => {
	return (
		<div className={styles.card} style={{width: width}}>
			<h3>{languageFile[localStorage.getItem("language")]["30819"]}</h3>
			{loader ? (
				<div className={styles.loader}>
					<div class="graph-loader">
						<div class="loader"></div>
					</div>
				</div>
			) : (
				<div className={styles.sourceList}>
					{sources.length > 0 ? (
						sources.map((source) => (
							<ReviewSourceItem
								key={source.name}
								source={source}
							/>
						))
					) : (
						<div className={styles.noData}>
							<Empty />
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ReviewSourcesCard;
