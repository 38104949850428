var keyMirror = require("keymirror");

module.exports = keyMirror({
	GET_PERFORMANCE_OVERVIEW: null,
	GET_STACKED_AREA_CHART: null,
	GET_STACKED_AREA_CHART_DOWNLOAD: null,
	GET_LOCATION_INSIGHT: null,
	GET_ACTIONS_TYPE: null,
	GET_LINE_CHART: null,
	GET_LINE_CHART_DOWNLOAD: null,
	GET_LOCAL_HISTOGRAM: null,
	GET_LOCAL_HISTOGRAM_DOWNLOAD: null,
	DATE_WISE_HISTO: null,
	LOCATION_CHART: null,
	CITY_CHART: null,
	REGION_CHART: null,
	HISTO_CHART_DOWNLOAD: null,
	GET_LOCATION_SUMMARY: null,
	LOCATION_HISTO_DATA: null,
	GET_PERFORMANCE_SUMMARY_REPORT: null
});
