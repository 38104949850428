var languageFile = require("../../../../public/languages/Translation");
let hour_translation=languageFile[localStorage.getItem('language')]["11535"];
let hours_translation=languageFile[localStorage.getItem('language')]["11540"];
let days_translation=languageFile[localStorage.getItem('language')]["22181"];
module.exports={
    colourLabelMap :{
        "0-4":{
            "color": "#08589E",
            "label": "< 4 "+ hour_translation
        },
        "4-12":{
            "color": "#2B8CBE",
            "label": "4-12 "+hours_translation
        } ,
        "12-24":{
            "color": "#4EB3D3",
            "label": "12-24 "+hours_translation
          },
          "24-48": {
            "color": "#7BCCC4",
            "label": "2 "+days_translation
          },
          "48-72":{
            "color": "#A8DDB5",
            "label": "3 "+days_translation
          },
          "72-96":{
            "color": "#CCEBC5",
            "label": "4 "+days_translation
          },
          "96-144":{
            "color": "#EAD19F",
            "label": "5-6 "+days_translation
          },
          "144-192":{
            "color": "#D7986E",
            "label": "7-8 "+days_translation
          },
          "192-336":{
            "color": "#D27042",
            "label": "9-14 "+days_translation
          }, 
          "336+":{
            "color": "#999999",
            "label": "14+ "+days_translation
          }
      }
}