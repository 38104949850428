import React from "react";
import styles from "./MetricCard.module.scss";
import "./style.scss";
import { Spin, Tooltip } from "antd";
import { TOOLTIP_CONSTANTS } from "../../routers/ReviewsDashboard/dashboardPageConstants";

const MetricCard = ({
	title,
	value,
	avgLabel,
	avgValue,
	changePercent,
	additionalInfo,
	additionalClass,
	loader,
	info,
	dateInterval,
	width
}) => {
	return (
		<div
			className={`${styles.metricCard} ${
				additionalClass ? additionalClass : ""
			}`} style={{width: width}}>
			<h3>{title}</h3>

			{loader ? (
				<div className={styles.loaderBlock}>
					<div class="graph-loader">
						<div class="loader"></div>
					</div>
				</div>
			) : (
				<>
					<div className={styles.metricValue}>
						<span
							className={`${styles.value} ${
								additionalClass ? "metric-value" : ""
							}`}>
							{value}
						</span>
					</div>
					<div className={styles.footer}>
						<Tooltip title={info ? info : ""}>
							<div className={styles.info}>
								{info && title == 'Locations' && (
									<p
										className={`${styles.avg} ${
											additionalClass ? "avg-value" : ""
										}`}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="1em"
											height="1em"
											viewBox="0 0 24 24"
											fill="none"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round">
											<circle
												cx="12"
												cy="12"
												r="10"></circle>
											<path d="M12 16v-4"></path>
											<path d="M12 8h.01"></path>
										</svg>
									</p>
								)}

								{avgLabel && (
									<p>
										{" "}
										{avgLabel}: {avgValue}
									</p>
								)}
								{additionalInfo && (
									<p
										className={`${styles.additionalInfo} ${
											additionalClass ? "additional-Info" : ""
										}`}>
										{additionalInfo}
									</p>
								)}
							</div>
						</Tooltip>

						{changePercent != undefined && (
							<Tooltip
								title={TOOLTIP_CONSTANTS.METRIC_TREND_TOOLTIP(
									changePercent > 0
										? "Increase"
										: changePercent < 0
										? "Decrease"
										: "No change",
									title,
									dateInterval,
								)}>
								<div
									className={`${styles.change} ${
										changePercent > 0
											? styles.positive
											: changePercent == 0
											? styles.neutral
											: styles.negative
									}`}>
									<span className={styles.arrow}>
										{changePercent > 0
											? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" ><path d="M440-240v-368L296-464l-56-56 240-240 240 240-56 56-144-144v368h-80Z"/></svg>
											: changePercent === 0 || changePercent === "0"
											? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg>
											: <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960"><path d="M440-240v-368L296-464l-56-56 240-240 240 240-56 56-144-144v368h-80Z"/></svg>}
									</span>
									<span>{Math.abs(changePercent).toFixed(1)}%</span>{" "}
								</div>
							</Tooltip>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default MetricCard;
