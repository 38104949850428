import AppDispatcher from "../../../flux/dispatcher/dispatcher";
// import AppDispatcher from 'dispatcher/dispatcher';
import Constant from "../constant/Constants";
import restClient from "../../MainFrame/action/APIClient";
import LogoutConstant from "../../MainFrame/constants/Constants";
var _ = require("lodash");

var GetActionsTypeCountAction = function () {};

GetActionsTypeCountAction.prototype = {
	getData: function (data) {
		data['filters']['insightFilters'] = {
			"metrics": [
				"ACTIONS_DRIVING_DIRECTIONS",
				"ACTIONS_PHONE",
				"ACTIONS_WEBSITE"
			]
		}
		restClient.getLocationInsightMetricCount(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error,
					});
				} else {
					AppDispatcher.dispatch({
						actionType: Constant.GET_ACTIONS_TYPE,
						data: error,
					});
				}
			} else if (response != null) {
				AppDispatcher.dispatch({
					actionType: Constant.GET_ACTIONS_TYPE,
					data: response,
				});
			}
		});
	},
};

export default new GetActionsTypeCountAction();
