var languageFile = require("../../../public/languages/Translation");

export const TOOLTIP_CONSTANTS = {
	METRIC_TREND_TOOLTIP: (trend, metricField, dateInterval) => {
		const language = localStorage.getItem("language");
		const keys = {
			Decrease: "30829",
			Increase: "30830",
			Default: "30831",
		};
		const translation = languageFile[language][keys[trend] || keys.Default];
		return translation
			.replace("{x data}", metricField)
			.replace("{xx}", dateInterval);
	},
	REVIEWS_INFO: languageFile[localStorage.getItem("language")]["30824"],
	AVG_RATING_INFO: languageFile[localStorage.getItem("language")]["30825"],
	HAPPINESS_SCORE_INFO: languageFile[localStorage.getItem("language")]["30826"],
	WORD_RATIO_INFO: languageFile[localStorage.getItem("language")]["30827"],
	RESPONSE_RATIO_INFO: languageFile[localStorage.getItem("language")]["30828"],
	RESPONSE_COUNT_INFO: languageFile[localStorage.getItem("language")]["30853"],
	RAPID_REPLY_INFO: languageFile[localStorage.getItem("language")]["30854"],
	LOCATIONS_INFO: languageFile[localStorage.getItem("language")]["30864"],
	AVG_LOCATION_INFO: languageFile[localStorage.getItem("language")]["30885"],
	COMMENT_RATIO_INFO: languageFile[localStorage.getItem("language")]["30879"],
	COMMENT_LENGTH_INFO: languageFile[localStorage.getItem("language")]["30880"],
};

export const METRIC_CARD_CONSTANTS = {
	REVIEWS: languageFile[localStorage.getItem("language")]["13574"],
	AVG_RATING: languageFile[localStorage.getItem("language")]["30814"],
	HAPPINESS: languageFile[localStorage.getItem("language")]["30816"],
	WORD_RATIO: languageFile[localStorage.getItem("language")]["30817"],
	RESPONSE_RATIO: languageFile[localStorage.getItem("language")]["30818"],
	RESPONSES: languageFile[localStorage.getItem("language")]["30850"],
	RAPID_REPLY_RATE: languageFile[localStorage.getItem("language")]["30851"],
	LOCATIONS: languageFile[localStorage.getItem("language")]["12374"],
	COMMENT_RATIO: languageFile[localStorage.getItem("language")]["22641"],
	COMMENT_LENGTH: languageFile[localStorage.getItem("language")]["22681"],
	AVG_LOCATION: languageFile[localStorage.getItem("language")]["30884"],
};

export const RECENT_CHART_DESC = {
	CHART_TOOLTIP: ( recentReviewCount,dateInterval) => {
		const language = localStorage.getItem("language");
		const translation = languageFile[language]['30823'];
		return translation
			.replace("{x}", recentReviewCount)
			.replace("{x}", dateInterval);
	},
}