import React from "react";
import { Empty, Switch } from "antd";
import languageFile from "../../../public/languages/Translation";
import styles from "./Wrapper.module.scss";
import { Spinner } from "react-bootstrap";

const DashboardReviewWaveChartWrapper = ({
	data = [],
	children,
	label = "",
	loader = false,
	desc = "",
	onChangeZoomChartView = () => {},
	showChartZoom = false,
	id = "",
	download,
	enableDownload = false,
	onDownloadClick = () => {},
}) => {
	// Retrieve the current language and safely access translation keys
	const language = localStorage.getItem("language");
	const getTranslation = (key, fallback = "") => {
		return languageFile[language] && languageFile[language][key]
			? languageFile[language][key]
			: fallback;
	};

	return (
		<div
			className={styles.card}
			id={id}>
			<div className={styles.headerBlock}>
				<span className={styles.header}>
					<h3>{label}</h3>
					<p>{desc}</p>
				</span>
				<span className={styles.headerSwitch}>
					<Switch
						size="default"
						checkedChildren={getTranslation("22481", "Zoom")}
						unCheckedChildren={getTranslation("22481", "Zoom")}
						onChange={onChangeZoomChartView}
						checked={showChartZoom}
					/>
					{data.length > 0 && enableDownload  &&
						( !download ? (
							<span
								className="excel-download"
								onClick={onDownloadClick}
								data-toggle="tooltip"
								title={getTranslation("10786", "Download")}></span>
						) : (
							download && (
								<div className="spinner-container excel-download-spinner">
									<Spinner
										noFadeIn
										name="pulse"
									/>
								</div>
							)
						))}
				</span>
			</div>

			{loader ? (
				<div className={styles.loaderBlock}>
					<div className="graph-loader">
						<div className="loader"></div>
					</div>
				</div>
			) : (
				<div className={styles.sourceList}>
					{data.length > 0 ? (
						children
					) : (
						<div className={styles.noData}>
							<Empty />
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default DashboardReviewWaveChartWrapper;
