import AppDispatcher from '../../../flux/dispatcher/dispatcher';
// import AppDispatcher from 'dispatcher/dispatcher';
import Constant from '../constant/Constants';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';
import urlConfig from '../../../config/index';
var moment = require('moment');
var _ = require("lodash");


var GetReviewHistogramAction = function () {

}

GetReviewHistogramAction.prototype = {

    getData: function (data) {
		restClient.getReviewHistogram(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
                else{
                    AppDispatcher.dispatch({
                        actionType: Constant.GET_HISTOGRAM_DATA,
                        data: error
                    });
                }
			} else if(response != null) {
                response['status'] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.GET_HISTOGRAM_DATA,
					data: response
				});
			}
		})
	},
	// downloadReviewLocation() {
	// 	var urlName = urlConfig.urlName;
		
	// 	var data = {
	// 		profileId: localStorage.getItem('profileId'),
	// 		granularity: granularity,
	// 		domainName: urlName,
	// 		sources: filters.sources != null ? filters.sources: [],
	// 		filters: filters
	// 	};
	// 	restClient.downloadReviewLocation(data, function (error, response) {
	// 		if (error) {
	// 			// console.log("error", error);
	// 			if (error.status === 401) {
	// 				AppDispatcher.dispatch({
	// 					actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
	// 					data: error
	// 				});
	// 			}
	// 		} else {
	// 			AppDispatcher.dispatch({
	// 				actionType: Constant.DOWNLOAD_REVIEW_LOCATION,
	// 				data: response
	// 			});
	// 		}
	// 	})
	// }
}

export default new GetReviewHistogramAction();