var languageFile = require("../../../../public/languages/Translation");
module.exports = {
    colorSpectrum: {
        0: "#93B9C6",
        1: "#0DB14B",
        2: "#FCB711",
        3: "#F37021",
        4: "#CC004C",
        5: "#6460AA",
        6: "#AAAAAA",
        7: "#19B2FF",
        8: "#9DC62D",
        9: "#AF4434",
        10: "#93B9C6"
    },
    starRating: [{ label: languageFile[localStorage.getItem('language')]["22371"], value: 1, key: 1, color: "#e22f30", isChecked: false ,s:30}, { label: languageFile[localStorage.getItem('language')]["22381"], value: 2, key: 2, color: "#FEBF00", isChecked: false ,s:25},
    { label: languageFile[localStorage.getItem('language')]["22391"], value: 3, key: 3, color: "#DADFE4", isChecked: false ,s:5},
    { label: languageFile[localStorage.getItem('language')]["22401"], value: 4, key: 4, color: "#91CF50", isChecked: false ,s:20}, { label: languageFile[localStorage.getItem('language')]["22411"], value: 5, key: 5, color: "#01B0F1", isChecked: false }],
    colorPick: {
        "1": "#E22F30",
        "2": "#FEBF00",
        "3": "#DADFE4",
        "4": "#91CF50",
        "5": "#01B0F1"
    },
    starRatingLabel: [{ label: '1Star', value: 1, key: 1 },
    { label: '2 Star', value: 2, key: 2 },
    { label: '3 Star', value: 3, key: 3 },
    { label: '4 Star', value: 4, key: 4 },
    { label: '5 Star', value: 5, key: 5 }],

}