import AppDispatcher from "../../../flux/dispatcher/dispatcher";
import Constant from "../constant/Constants";
import restClient from "../../MainFrame/action/APIClient";
import LogoutConstant from "../../MainFrame/constants/Constants";

var GetResponseByRatingAction = function () {};

GetResponseByRatingAction.prototype = {
	getRating: function (data) {
		restClient.getAggregatedReplyRatingCount(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error,
					});
				} else {
					AppDispatcher.dispatch({
						actionType: Constant.GET_RESPONSE_BY_RATING,
						data: error,
					});
				}
			} else if (response != null) {
				response["status"] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.GET_RESPONSE_BY_RATING,
					data: response,
				});
			}
		});
	},
};

export default new GetResponseByRatingAction();
