import React from "react";
import styles from "./Wrapper.module.scss";
import RatingChart from "../ResponseChart";
import { Empty, Spin } from "antd";

const RatingGraphWrapper = ({ id,data, label, tooltipText, loader,dateRange, width }) => {
	return (
		<div className={styles.card} style={{width: width}}>
			<h3>{label}</h3>
			{loader ? (
				<div className={styles.loaderBlock}>
					<div class="graph-loader">
						<div class="loader"></div>
					</div>
				</div>
			) : (
				<div className={styles.sourceList}>
					{data && data.length > 0 ? (
						<RatingChart
							id={id}
							data={data}
							tooltipText={tooltipText}
							dateRange={dateRange}
						/>
					) : (
						<div className={styles.noData}>
							<Empty />
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default RatingGraphWrapper;
