import _ from 'lodash';

export const defaultGraphDataFormating=(fields,data,type)=>{
    type=type?type:'avg'
    let starmap={"1":"1 star","2":"2 star","3":"3 star","4":"4 star","5":"5 star"}
    starmap[type]="avg"
    let downloadDataFormat=[];
    downloadDataFormat=_.map(data,(item,key)=>{
        let resp={date:item.date,none:0}
        _.map(fields,(field,index)=>{
            if(item[field])
            resp[(starmap[field])]=item[field];
        })
        return resp
    })
    return downloadDataFormat;
}