import Dispatcher from "../../../flux/dispatcher/dispatcher";
import Constants from "../constant/Constants";
// var Dispatcher = require('dispatcher/dispatcher');
var EventEmitter = require("events").EventEmitter;
var RESPONSE_CHANGE_EVENT = "globalResponse";
// var Constants = require('components/Dashboard/constants/GetHistogramConstant');
var assign = require("object-assign");

var response = {};

function parseResponse(resp) {
	response = resp;
}

var GetPerformanceOverviewStore = assign({}, EventEmitter.prototype, {
	emitChangeEvent: function (event) {
		this.emit(event);
	},
	bind: function (callback) {
		this.on(RESPONSE_CHANGE_EVENT, callback);
	},
	unbind: function (callback) {
		this.removeListener(RESPONSE_CHANGE_EVENT, callback);
	},
	getResponse: function () {
		return response;
	},
});

Dispatcher.register(function (action) {
	switch (action.actionType) {
		case Constants.GET_PERFORMANCE_OVERVIEW:
			var resp = action.data;
			parseResponse(resp);
			GetPerformanceOverviewStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
		default:
	}
});

export default GetPerformanceOverviewStore;
