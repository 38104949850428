import React from "react";
var _ = require("lodash");

export const findLabelValue = (data, label) => {
	if (!_.isEmpty(data)) {
		var value =
			_.find(data, function (item) {
				return item.name === label;
			}) &&
			_.find(data, function (item) {
				return item.name === label;
			}).value;
		return value && value.toLocaleString();
	} else {
		return "-";
	}
};
export const findLabelValueRound = (data, label) => {
	if (!_.isEmpty(data)) {
		var value =
			_.find(data, function (item) {
				return item.name === label;
			}) &&
			_.find(data, function (item) {
				return item.name === label;
			}).value;
		return parseFloat(value).toFixed(1);
	} else {
		return "-";
	}
};

export const findPercent = (label, data) => {
	if (data) {
		if (data.length === 0) {
			return "-";
		} else {
			var sum = 0;
			for (var i in data) {
				sum = sum + data[i].value;
			}
			var percent;
			for (var i in data) {
				if (data[i].name === label) {
					percent = (data[i].value / sum) * 100;
				}
			}
			if (isNaN(percent)) {
				return "-";
			} else {
				return Math.round(percent * 100) / 100 + "%";
			}
		}
	}
};

export const modifyPieChartData = (data, hashMap) => {
	var pieChartData = [];
	for (var key in data) {
		pieChartData.push({ name: hashMap[key], value: data[key] });
	}
	return pieChartData;
};
