import React from "react";
import {
	ResponsiveContainer,
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ComposedChart,
	Bar,
	AreaChart,
	Area,
} from "recharts";
import LocalHistogramStore from "./store/LocalHistogramStore.js";
import { Spin } from "antd";
import LocalHistogramAction from "./action/LocalHistogramAction.js";
var moment = require("moment");
var languageFile = require("../../../../public/languages/Translation.js");

class LocalHistogramComponent extends React.Component {
	constructor(props) {
		super(props);

		this.getData = this.getData.bind(this);
	}

	componentWillMount() {
		this.getData();
	}
	getData() {
		var hashMap = {
			DRIVING_DIRECTIONS_PERCENT:
				languageFile[localStorage.getItem("language")]["10792"],
			PHONE_PERCENT: languageFile[localStorage.getItem("language")]["13132"],
			WEBSITE_PERCENT: languageFile[localStorage.getItem("language")]["14523"],
			date: "date",
		};
		if (this.props.data.length > 0) {
			var data = this.props.data.map(function (item, index) {
				var singleItem = {};
				for (var i in item) {
					var value = item[i];
					if (i === "date") {
						value = moment(value).format("ll");
					} else {
						if (value === 0) {
							value = null;
						}
					}
					singleItem[hashMap[i]] = value;
				}
				return singleItem;
			});

			this.setState({
				graphData: data,
				loading: false,
			});
		}
	}

	formatterFunction(val, total) {
		return `${val}%`;
	}
	renderTooltipContent(val, name, unit) {
		return `${val}%`;
	}
	render() {
		if (this.state.loading) {
			return (
				<div className="loader-main">
					<div className="loader"></div>
				</div>
			);
		}

		if (this.state.graphData.length === 0) {
			return (
				<div className="loader-main nightmare-check-stacked-line">
					{languageFile[localStorage.getItem("language")]["23191"]}
				</div>
			);
		}

		return (
			<span className="nightmare-check-stacked-line" style ={{display: 'flex', height: '350px'}}>
				<ResponsiveContainer
					width="100%"
					height="100%"
					maxHeight={"350px"}>
					<AreaChart
						data={this.state.graphData}
						margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
						<XAxis dataKey="date" />
						<YAxis
							yAxisId={0}
							orientation="left"
							tickFormatter={this.formatterFunction}
						/>
						{/* <YAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} yAxisId={1} orientation="right" /> */}
						<Tooltip formatter={this.renderTooltipContent} />
						<Legend
							iconType="rect"
							align="left"
							wrapperStyle={{ border: "0px" }}
						/>
						<Area
							dataKey={languageFile[localStorage.getItem("language")]["10792"]}
							stroke="#08589e"
							fill="#08589e"
							stackId={1}
							fillOpacity={1}
						/>
						<Area
							dataKey={languageFile[localStorage.getItem("language")]["13132"]}
							stroke="#F67428"
							fill="#F67428"
							stackId={1}
							fillOpacity={1}
						/>
						<Area
							dataKey={languageFile[localStorage.getItem("language")]["14523"]}
							stroke="#37942D"
							fill="#37942D"
							stackId={1}
							fillOpacity={1}
						/>
					</AreaChart>
				</ResponsiveContainer>
			</span>
		);
	}
}

export default LocalHistogramComponent;
