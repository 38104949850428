import React, { useEffect, useRef, useState } from "react";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5 from "@amcharts/amcharts5";
import { IconTooltip } from "./IconTooltip/IconTooltip";

import styles from "./index.module.scss";
import { sourceImages } from "../sharedComponets/PieChartAm/Children/souceData";

const DEFAULT_ZOOM_VALUE = 30;

const ReviewReviewsChart = ({ data }) => {
	const [tooltipData, setTooltipData] = useState({ visible: false });
	const chartDiv = useRef(null);

	const onTooltipHover = (event) => {
		const { target, point } = event;
		if (target && target.dataItem && target.dataItem.dataContext) {
			setTooltipData({
				visible: true,
				positionX: point.x,
				positionY: point.y,
				data: target.dataItem.dataContext,
				canvasWidth: event.originalEvent.target.offsetWidth,
				canvasHeight: event.originalEvent.target.offsetHeight,
			});
		}
	};

	const onTooltipBlur = () => {
		setTooltipData({ visible: false });
	};

	useEffect(() => {
		if (chartDiv.current) {
		const baseInterval = { timeUnit: "hour", count: 1 };
		const root = am5.Root.new(chartDiv.current);

		const chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				panX: true,
				panY: true,
				wheelX: "none",
				wheelY: "none",
				pinchZoomY: true,
				paddingLeft: 0,
			}),
		);
		chart.zoomOutButton.set("forceHidden", true);
		chart.get("colors").set("step", 3);

		const yRenderer = am5xy.AxisRendererY.new(root, {
			minGridDistance: 20,
			minorGridEnabled: true,
		});

		const yAxis = chart.yAxes.push(
			am5xy.CategoryAxis.new(root, {
				maxDeviation: 0.3,
				categoryField: "category",
				renderer: yRenderer,
				tooltip: am5.Tooltip.new(root, {}),
			}),
		);

		yRenderer.labels.template.setAll({
			multiLocation: 0.5,
		});

		yRenderer.grid.template.setAll({
			location: 1,
		});

		// Date axis for the x-axis
		const xAxis = chart.xAxes.push(
			am5xy.DateAxis.new(root, {
				renderer: am5xy.AxisRendererX.new(root, {
					strokeOpacity: 0.1,
					minGridDistance: 60,
				}),
				baseInterval,
				maxDeviation: 0.3,
				maxPrecision: 0,
			}),
		);

		xAxis.get("renderer").grid.template.setAll({
			visible: false,
		});

		// Create series
		const series = chart.series.push(
			am5xy.ColumnSeries.new(root, {
				xAxis: xAxis,
				yAxis: yAxis,
				baseAxis: yAxis,
				valueXField: "date", // Use date for x-axis
				categoryYField: "rating", // Use rating for y-axis
				tooltip: am5.Tooltip.new(root, {
					labelText: "{categoryY}: {valueX}",
				}),
			}),
		);

		series.columns.template.setAll({
			height: 0,
			opacity: 0,
		});
		const scrollbar = chart.set(
			"scrollbarX",
			am5xy.XYChartScrollbar.new(root, {
				orientation: "horizontal",
				height: 10,
			}),
		);
		chart.bottomAxesContainer.children.push(scrollbar);


		const circleTemplate = am5.Template.new({});
		series.bullets.push(function (localRoot, sprite, dataItem) {
			const bulletContainer = am5.Container.new(localRoot, {});
			const background = bulletContainer.children.push(
				am5.Rectangle.new(localRoot, {
					fill: "#fff",
					width: 20,
					height: 20,
					cornerRadius: 5,
					centerX: am5.p50,
					centerY: am5.p50,
				}),
			);

			background.events.on("pointerover", onTooltipHover);

			background.events.on("pointerout", onTooltipBlur);

			const circle = bulletContainer.children.push(
				am5.Circle.new(localRoot, { radius: 25 }, circleTemplate),
			);

			const maskCircle = bulletContainer.children.push(
				am5.Circle.new(localRoot, { radius: 25 }),
			);

			const imageContainer = bulletContainer.children.push(
				am5.Container.new(localRoot, {
					mask: maskCircle,
				}),
			);

			const picture = imageContainer.children.push(
				am5.Picture.new(localRoot, {
					templateField: "pictureSettings",
					centerX: am5.p50,
					centerY: am5.p50,
					width: 20,
					height: 20,
				}),
			);

			background.events.on("click", (event) => {
				if (
					event.target &&
					event.target.dataItem &&
					event.target.dataItem.dataContext
				) {
					window.location.href = `/reviews/reviewId=${event.target.dataItem.dataContext.reviewDocId}`;
				}
			});

			return am5.Bullet.new(localRoot, {
				locationX: 1,
				locationY: 0.5,
				sprite: bulletContainer,
			});
		});
		// Process data to map to chart format
		const chartData = data.map((item) => ({
			date: Date.parse(item.updateTime), // Convert date to timestamp for x-axis
			rating: item.starRating, // Rating for y-axis
			pictureSettings: { src: sourceImages[item.source] },
			locationName: item.locationName || "",
			reviewer:
				item.reviewer && item.reviewer.displayName
					? item.reviewer && item.reviewer.displayName
					: "",
			storeCode: item.storeCode || "",
			reviewDocId: item.reviewDocId || "",
		}));

		yAxis.data.setAll(
			[
				{ category: "5", color: "#4CAF50" },
				{ category: "4", color: "#03A9F4" },
				{ category: "3", color: "#ababab" },
				{ category: "2", color: "#FF9800" },
				{ category: "1", color: "#FF5722" },
			].reverse(),
		);
		yAxis.get("renderer").labels.template.setAll({
			fontSize: 16,
			fontWeight: 500,
		});
		yAxis
			.get("renderer")
			.labels.template.adapters.add("text", (value, target) => {
				if (target.dataItem && target.dataItem.dataContext) {
					const ratingLabel = target.dataItem.dataContext.category || "";
					const color = target.dataItem.dataContext.color || "#ff0000";
					return `[${color}]${ratingLabel} ★[/]`;
				}
				return value;
			});

		series.data.setAll(chartData);
		// Make stuff animate on load
		series.appear(1000);
		chart.appear(1000, 100);

		if (chartData.length >= DEFAULT_ZOOM_VALUE) {
			setTimeout(() => {
				let startValue = 1 - DEFAULT_ZOOM_VALUE / chartData.length;
				xAxis.zoom(startValue, 1);
			}, 1200);
		}

		return () => {
			root.dispose();
		};
	}
	}, [data]);

	return (
		<div className={styles.reviewReviewsChart} >
			<div
				ref={chartDiv}
				id="chartdiv"
				className={styles.reviewReviewsChart__chart}></div>
			{tooltipData.visible && <IconTooltip {...tooltipData} />}
		</div>
	);
};

export default ReviewReviewsChart;
