import Dispatcher from "../../../flux/dispatcher/dispatcher";
import Constants from "../constant/Constants";
var EventEmitter = require("events").EventEmitter;
var RESPONSE_CHANGE_EVENT = "globalResponse";
var assign = require("object-assign");

var response = null;

function parseResponse(resp) {
	response = resp;
}

var ReviewRatingWaveChartStore = assign({}, EventEmitter.prototype, {
	emitChangeEvent: function (event) {
		this.emit(event);
	},
	bind: function (callback) {
		this.on(RESPONSE_CHANGE_EVENT, callback);
	},
	unbind: function (callback) {
		this.removeListener(RESPONSE_CHANGE_EVENT, callback);
	},
	getResponse: function () {
		return response;
	},
});

Dispatcher.register(function (action) {
	switch (action.actionType) {
		case Constants.GET_REVIEW_VOLUME_AVERAGE_RATING:
			var resp = action.data;
			parseResponse(resp);
			ReviewRatingWaveChartStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
			break;
		default:
	}
});

export default ReviewRatingWaveChartStore;
