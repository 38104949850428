import React from "react";
import styles from "./ReviewSourceItem.module.scss";
import {
	sourceImages,
	sourceNames,
} from "../sharedComponets/PieChartAm/Children/souceData";

const ReviewSourceItem = ({ source }) => (
	<div className={styles.sourceItem}>
		<img
			src={sourceImages[source.source]}
			className={styles.icon}
		/>
		<span className={styles.name}>
			{source.source == "google" ? "Google" : sourceNames[source.source]}
		</span>
		<span className={styles.rating}>
			{source.avg}&nbsp;({source.reviewCount})
		</span>
	</div>
);

export default ReviewSourceItem;
