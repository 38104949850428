import AppDispatcher from "../../../flux/dispatcher/dispatcher";
// import AppDispatcher from 'dispatcher/dispatcher';
import Constant from "../constant/Constants";
import restClient from "../../MainFrame/action/APIClient";
import LogoutConstant from "../../MainFrame/constants/Constants";
var _ = require("lodash");

var GetStackedAreaChartAction = function () {};

GetStackedAreaChartAction.prototype = {
	loadData: function (data) {
		data.filters["insightFilters"] = {
			metrics: [
				"BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
				"BUSINESS_IMPRESSIONS_MOBILE_MAPS",
				"BUSINESS_IMPRESSIONS_MOBILE_SEARCH",
				"BUSINESS_IMPRESSIONS_DESKTOP_SEARCH",
			],
		};
		restClient.getLocationInsightHistogram(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error,
					});
				} else {
					AppDispatcher.dispatch({
						actionType: Constant.GET_STACKED_AREA_CHART,
						data: error,
					});
				}
			} else if (response != null) {
				response["status"] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.GET_STACKED_AREA_CHART,
					data: response,
				});
			}
		});
	},
};

export default new GetStackedAreaChartAction();
