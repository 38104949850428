import React from "react";
import styles from "./Wrapper.module.scss";
import { Empty, Spin } from "antd";

var languageFile = require("../../../public/languages/Translation");
var Spinner = require("react-spinkit");

const LPOChartWrapper = ({
	mapMobileCount,
	mapMobilePercent,
	mapDesktopCount,
	mapDesktopPercent,
	searchMobileCount,
	searchMobilePercent,
	searchDesktopCount,
	searchDesktopPercent,
	onDownloadClick,
	data,
	children,
	desc,
	label,
	loader,
	download,
	chart,
}) => {
	let params = {};
	if (chart == "customers") {
		params = {
			mapDesktopCount,
			mapDesktopPercent,
			searchMobileCount,
			searchMobilePercent,
			searchDesktopPercent,
			searchDesktopCount,
			mapMobilePercent,
			mapMobileCount,
		};
	} else if (chart == "action") {
		params = {
			mapDesktopCount,
			mapDesktopPercent,
			searchMobileCount,
			searchMobilePercent,
			mapMobilePercent,
			mapMobileCount,
		};
	} else if (chart == "conversion") {
		params = {
			mapMobileCount,
			mapDesktopCount,
			mapDesktopPercent,
		};
	}
	return (
		<div className={styles.card}>
			<div className={styles.headerSction}>
				<div className={styles.header}>
					<h3>{label}</h3>
					<p>{desc}</p>
				</div>
				{data && data.length > 0 && !download ? (
					<span
						className="excel-download"
						onClick={onDownloadClick}
						data-toggle="tooltip"
						title={
							languageFile[localStorage.getItem("language")]["10786"]
						}></span>
				) : data.length > 0 && download ? (
					<div className="spinner-container excel-download-spinner">
						<Spinner
							noFadeIn
							name="pulse"
						/>
					</div>
				) : null}
			</div>
			{loader ? (
				<div className={styles.loaderBlock}>
					<div class="graph-loader">
						<div class="loader"></div>
					</div>
				</div>
			) : (
				<div className={styles.sourceList}>
					{getChartInfo(chart, params)}
					{data && data.length > 0 ? (
						children
					) : (
						<div className={styles.noData}>
							<Empty />
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default LPOChartWrapper;

const getChartInfo = (chart, params) => {
	if (chart == "customers") {
		return (
			<div className={styles.infoWrapper}>
				<div className={styles.infoContent}>
					<span className={styles.info}>
						<span>{params.mapMobileCount}</span>
						<span>{params.mapMobilePercent}</span>
						<span>
							{languageFile[localStorage.getItem("language")]["2073"]}
						</span>
					</span>
					<span className={styles.info}>
						<span>{params.searchMobileCount}</span>
						<span>{params.searchMobilePercent}</span>
						<span>
							{languageFile[localStorage.getItem("language")]["2074"]}
						</span>
					</span>
				</div>
				<div className={styles.infoContent}>
					<span className={styles.info}>
						<span>{params.mapDesktopCount}</span>
						<span>{params.mapDesktopPercent}</span>
						<span>
							{languageFile[localStorage.getItem("language")]["2075"]}
						</span>
					</span>
					<span className={styles.info}>
						{" "}
						<span>{params.searchDesktopCount}</span>
						<span>{params.searchDesktopPercent}</span>
						<span>
							{languageFile[localStorage.getItem("language")]["2076"]}
						</span>
					</span>
				</div>
			</div>
		);
	}
	if (chart == "action") {
		return (
			<div
				className={styles.infoWrapper}
				style={{ marginBottom: "80px" }}>
				<div className={styles.infoContent}>
					<span className={styles.info}>
						<span>{params.mapMobileCount}</span>
						<span>{params.mapMobilePercent}</span>
						<span>
							{languageFile[localStorage.getItem("language")]["10792"]}
						</span>
					</span>
				</div>
				<div className={styles.infoContent}>
					<span className={styles.info}>
						<span>{params.mapDesktopCount}</span>
						<span>{params.mapDesktopPercent}</span>
						<span>
							{languageFile[localStorage.getItem("language")]["13132"]}
						</span>
					</span>
				</div>
				<div className={styles.infoContent}>
					<span className={styles.info}>
						<span>{params.searchMobileCount}</span>
						<span>{params.searchMobilePercent}</span>
						<span>
							{languageFile[localStorage.getItem("language")]["14523"]}
						</span>
					</span>
				</div>
			</div>
		);
	}
	if (chart == "action") {
		return (
			<div
				className={styles.infoWrapper}
				style={{ marginBottom: "80px" }}>
				<div className={styles.infoContent}>
					<span className={styles.info}>
						<span>{params.mapMobileCount}</span>
						<span>{params.mapMobilePercent}</span>
						<span>
							{languageFile[localStorage.getItem("language")]["10792"]}
						</span>
					</span>
				</div>
				<div className={styles.infoContent}>
					<span className={styles.info}>
						<span>{params.mapDesktopCount}</span>
						<span>{params.mapDesktopPercent}</span>
						<span>
							{languageFile[localStorage.getItem("language")]["13132"]}
						</span>
					</span>
				</div>
				<div className={styles.infoContent}>
					<span className={styles.info}>
						<span>{params.searchMobileCount}</span>
						<span>{params.searchMobilePercent}</span>
						<span>
							{languageFile[localStorage.getItem("language")]["14523"]}
						</span>
					</span>
				</div>
			</div>
		);
	} else {
		return (
			<div
				className={styles.infoWrapper}
				style={{ marginBottom: "80px" }}>
				<div className={styles.infoContent}>
					<span className={styles.info}>
						<span>
							{params.mapMobileCount == "NaN%" ? "-" : params.mapMobileCount}
						</span>
						<span>
							{languageFile[localStorage.getItem("language")]["10792"]}
						</span>
					</span>
				</div>
				<div className={styles.infoContent}>
					<span className={styles.info}>
						<span>
							{params.mapDesktopCount == "NaN%" ? "-" : params.mapDesktopCount}
						</span>
						<span>
							{languageFile[localStorage.getItem("language")]["13132"]}
						</span>
					</span>
				</div>
				<div className={styles.infoContent}>
					<span className={styles.info}>
						<span>
							{params.mapDesktopPercent == "NaN%"
								? "-"
								: params.mapDesktopPercent}
						</span>
						<span>
							{languageFile[localStorage.getItem("language")]["14523"]}
						</span>
					</span>
				</div>
			</div>
		);
	}
};
