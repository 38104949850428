var keyMirror = require("keymirror");

module.exports = keyMirror({
	GET_REVIEWS_OVERVIEW: null,
	GET_RECENT_REVIEWS: null,
	GET_REVIEW_BY_RATING: null,
	GET_RESPONSE_BY_RATING: null,
	GET_REVIEWS_BY_SOURCE: null,
	GET_HISTOGRAM_DATA: null,
	GET_DATEWISE_HISTOGRAM_DATA: null,
	TOP_LOCATION_ACTION: null,
	GET_REVIEW_RESPONSE_OVERVIEW: null,
	GET_RESPONSE_HISTOGRAM: null,
	GET_RESPONSE_TIME_HISTOGRAM: null,
	GET_REVIEW_RESPONSE_HISTOGRAM: null,
	GET_LOCATION_RATINGL: null,
	GET_GRID_OVERVIEW: null,
	GET_REVIEW_VOLUME_AVERAGE_RATING: null,
	GET_ENERGY_OVERVIEW: null,
	GET_COMMENT_HISTOGRAM: null,
	GET_WORD_HISTOGRAM: null,
	GET_COMMENT_WORDCOUNT: null,
	GET_COMMENT_COUNT_RATIO: null,
	GET_COMMENT_LENGTH_RESPONSE: null,
	GET_LOCATION_REVIEW_SUMMARY: null,
	GET_SENTIMENT_OVERVIEW: null,
	GET_REVIEW_SUMMARY_REPORT: null,
	GET_SENTIMENT_BY_TOPIC: null
});
