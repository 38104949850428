import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { useHistory } from "react-router-dom";


var languageFile = require("../../../public/languages/Translation");

const RatingChart = ({ data, tooltipText, dateRange, id }) => {
	const chartDiv = useRef(null);
	const history = useHistory();

	useEffect(() => {
		if (chartDiv.current) {
			const root = am5.Root.new(chartDiv.current);

			if (root.logo) {
				root.logo.dispose();
			}

			const chart = root.container.children.push(
				am5xy.XYChart.new(root, {
					layout: root.horizontalLayout,
					zoomable: false,
				}),
			);

			chart.zoomOutButton.dispose();

			const yAxis = chart.yAxes.push(
				am5xy.CategoryAxis.new(root, {
					renderer: am5xy.AxisRendererY.new(root, {
						minGridDistance: 0,
						grid: null,
					}),
					categoryField: "rating",
					axisFills: { visible: false },
				}),
			);

			yAxis.get("renderer").grid.template.setAll({
				visible: false,
			});

			yAxis.data.setAll(
				[
					{ rating: "5", color: "#4CAF50" },
					{ rating: "4", color: "#03A9F4" },
					{ rating: "3", color: "#ababab" },
					{ rating: "2", color: "#FF9800" },
					{ rating: "1", color: "#FF5722" },
				].reverse(),
			);

			yAxis.get("renderer").labels.template.setAll({
				fontSize: 18,
				fontWeight: 500,
			});

			yAxis
				.get("renderer")
				.labels.template.adapters.add("text", (value, target) => {
					if (target.dataItem && target.dataItem.dataContext) {
						const ratingLabel = target.dataItem.dataContext.rating || "";
						const color = target.dataItem.dataContext.color || "#ff0000";
						return `[${color}] ${ratingLabel}[/]`;
					}
					return value;
				});

			const xAxis = chart.xAxes.push(
				am5xy.ValueAxis.new(root, {
					renderer: am5xy.AxisRendererX.new(root, { grid: null }),
					min: 0,
					max: 100,
					strictMinMax: true,
					extraMax: 0.1,
					grid: null,
					axisFills: { visible: false },
				}),
			);

			xAxis.set("visible", false);
			xAxis.get("renderer").grid.template.setAll({
				visible: false,
			});

			// Define colors for each rating level

			// Background series for the remaining length (grey background)
			const backgroundSeries = chart.series.push(
				am5xy.ColumnSeries.new(root, {
					xAxis: xAxis,
					yAxis: yAxis,
					valueXField: "maxValue", // Full bar length to represent 100%
					categoryYField: "rating",
					clustered: false,
				}),
			);

			backgroundSeries.columns.template.setAll({
				fill: am5.color("#eae8e7"), // Grey color for remaining length
				stroke: 0, // Remove border color
				height: 16,
				cornerRadiusTL: 20,
				cornerRadiusBL: 20,
				cornerRadiusTR: 20,
				cornerRadiusBR: 20,
			});

			// Series for the actual rating value (colorful bar)
			const series = chart.series.push(
				am5xy.ColumnSeries.new(root, {
					xAxis: xAxis,
					yAxis: yAxis,
					valueXField: "value", // Actual value for each rating
					categoryYField: "rating",
					clustered: false,
				}),
			);
			// Tooltip configuration function
			const createCustomTooltip = (root) => {
				let tooltip = am5.Tooltip.new(root, {
					pointerOrientation: "vertical", // Ensures the tooltip follows horizontal movement
					getFillFromSprite: false,
					autoTextColor: false,
				});
				tooltip.get("background").setAll({
					fill: am5.color(0x000000),
					fillOpacity: 0.8,
				});
				tooltip.label.setAll({
					fill: am5.color(0xffffff),
				});
				return tooltip; // Prevent inheriting colors
			};
			// Apply tooltip to the filled series (foreground bar)
			series.columns.template.setAll({
				tooltipText:
					tooltipText === "Reviews" ? languageFile[localStorage.getItem("language")]["30832"] : "", // Show count instead of rating
				tooltipY: 0,
				width: am5.percent(100), // Ensure full-width tooltip area
				opacity: 1,
				interactive: true, // Enable interaction
			});
			series.set("tooltip", createCustomTooltip(root));
			// For the background series
			backgroundSeries.columns.template.setAll({
				tooltipText:
					tooltipText === "Reviews" ? languageFile[localStorage.getItem("language")]["30832"] : "", // Show count instead of rating
				tooltipY: 0,
				width: am5.percent(100),
				opacity: 1, // Ensure the column spans the full width
				interactive: true, // Enable interaction across the column
			});
			backgroundSeries.set("tooltip", createCustomTooltip(root));

			series.columns.template.setAll({
				stroke: 0, // Remove border color
				height: 16,
				cornerRadiusTL: 20,
				cornerRadiusBL: 20,
				cornerRadiusTR: 20,
				cornerRadiusBR: 20,
			});

			// Add count labels inside the bars
			series.bullets.push((root, target, dataItem) => {
				// Access the current data item's count
				const currentCount = dataItem.dataContext.value;

				// Calculate the percentage dynamically
				const bulletData = {
					tooltipText,
					text: `${currentCount}%`,
				};

				return am5.Bullet.new(root, {
					locationX: 0, // Position the label at the center of the bar
					sprite: am5.Label.new(root, {
						text: bulletData.text, // Bind the count or percentage dynamically
						fill: am5.color("#000"), // Text color
						centerY: am5.percent(50), // Vertically center the text
						centerX: am5.percent(0), // Center horizontally
						fontWeight: "400",
						paddingLeft: 5,
						populateText: true,
						fontSize: "12px",
					}),
				});
			});

			// Assign colors to the columns based on the data

			series.columns.template.adapters.add("fill", (fill, target) => {
				const dataItem = target.dataItem;
				return dataItem ? dataItem.dataContext.color : fill;
			});

			if (!data || !Array.isArray(data)) {
				console.error("Data is missing or not in the correct format");
				return;
			}
			const ratingColor = {
				5: "#4CAF50",
				4: "#03A9F4",
				3: "#ababab",
				2: "#FF9800",
				1: "#FF5722",
			};
			// Data for the background series with max values
			backgroundSeries.data.setAll(
				data.map((item) => {
					let highestCount = Math.max.apply(
						Math,
						data &&
							data.map(function (o) {
								return o.count;
							}),
					);
					const processedItem = {
						rating: item.starRating,
						maxValue: 100, // Full bar length
						count: item.count || 0, // Set count based on the actual value
					};

					// Add console log to debug the transformed data
					return processedItem;
				}),
			);
			series.columns.template.events.on("click", (ev) => {
				if (tooltipText == "Reviews") {
					const dataContext =
						ev.target.dataItem && ev.target.dataItem.dataContext;
					if (dataContext) {
						const rating = dataContext.rating;
						history.push(
							`/reviews/inbox?from=${dateRange.from}&to=${dateRange.to}&rating=${rating}`,
						);
					} else {
						console.error("Data context is not available for the clicked bar.");
					}
				} else return null;
			});
			// Data for the actual rating bars
			series.data.setAll(
				data.map((item) => {
					return {
						rating: item.starRating,
						value: item.percent.toFixed(0) || 0, // Ensure `value` is present
						color: ratingColor[item.starRating] || "#FF5722", // Default color if not specified
					};
				}),
			);

			setTimeout(() => {
				// 	const canvases = document.querySelectorAll('canvas');
				// 	if (canvases) {
				// 		const secondCanvas = canvases[1];
				// 		secondCanvas.style.top = '20px'
				// 		canvases[0].style.left = '-5px'
				// }}, 1100);
				const chart1Canvases = document.querySelectorAll("#ratingChart canvas");
				if (chart1Canvases.length > 0) {
					if (chart1Canvases[1] && chart1Canvases[1].style) {
						chart1Canvases[1].style.top = "20px";
					}
					chart1Canvases[0].style.left = "-5px";
				}

				// Chart 2 adjustments
				const chart2Canvases = document.querySelectorAll(
					"#responseChart canvas",
				);
				if (chart2Canvases.length > 0) {
					if (chart2Canvases[1] && chart2Canvases[1].style) {
						chart2Canvases[1].style.top = "20px";
					}
					chart2Canvases[0].style.left = "-5px";
				}
			}, 1200);

			series.appear(1000);
			chart.appear(1000, 100);

			return () => {
				root.dispose();
			};
		}
	}, [data]);

	return (
		<div
			id={id}
			ref={chartDiv}
			style={{
				width: "100%",
				height: "180px",
			}}></div>
	);
};

export default RatingChart;
