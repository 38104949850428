import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";
import languageFile from "../../../public/languages/Translation";

am4core.useTheme(am4themesAnimated);

class ReviewRatingWaveChart extends Component {
	componentDidMount() {
		this.reviewWaveChart();
	}
	formatWaveData = (waveGraphData) => {
		let self = this;
		let formattedData = _.map(waveGraphData, (item, key) => {
			let waveItems = item;
			waveItems["value"] = 376;
			waveItems["id"] = item.locationName;
			waveItems["avgStarRating"] = _.round(item.avgStarRating, 2);
			waveItems["color"] =
				(self.props.nodeColorMap[item.brandName] &&
					am4core.color(self.props.nodeColorMap[item.brandName])) ||
				am4core.color("#0089D0");
			return waveItems;
		});
		return formattedData;
	};

	reviewWaveChart() {
		var bubbleChart = am4core.create("wavechart", am4charts.XYChart);
		var valueAxisX = bubbleChart.xAxes.push(new am4charts.ValueAxis());
		valueAxisX.renderer.ticks.template.disabled = true;
		valueAxisX.renderer.axisFills.template.disabled = true;
		// valueAxisX.renderer.grid.template.disabled= true;
		valueAxisX.renderer.axisFills.template.disabled = false;
		// valueAxisX.renderer.axisFills.template.fillOpacity = 0.05;
		valueAxisX.renderer.line.strokeOpacity = 1;
		valueAxisX.renderer.line.strokeWidth = 1;
		valueAxisX.renderer.line.stroke = "#8c8c8c";

		var valueAxisY = bubbleChart.yAxes.push(new am4charts.ValueAxis());
		valueAxisY.renderer.ticks.template.disabled = true;
		valueAxisY.renderer.axisFills.template.disabled = true;
		// valueAxisY.renderer. .template.disabled = true;
		valueAxisY.renderer.axisFills.template.disabled = false;
		valueAxisY.renderer.minGridDistance = 60;
		// valueAxisY.renderer.axisFills.template.fillOpacity = 0.05;
		valueAxisY.renderer.line.strokeOpacity = 1;
		valueAxisY.renderer.line.strokeWidth = 1;
		valueAxisY.renderer.line.stroke = "#8c8c8c";
		valueAxisY.min = 0;
		valueAxisY.max = 6;
		valueAxisY.renderer.labels.template.adapter.add(
			"textOutput",
			function (text, target) {
				if (text.startsWith("6")) {
					return "";
				} else return text;
			},
		);

		var series = bubbleChart.series.push(new am4charts.LineSeries());
		series.dataFields.valueX = "totalReviews";
		series.dataFields.valueY = "avgStarRating";
		series.dataFields.value = "value";
		series.dataFields.color = "color";
		series.dataFields.hidden = "hidden";
		series.strokeOpacity = 0;
		series.sequencedInterpolation = true;
		series.tooltip.pointerOrientation = "vertical";

		var bullet = series.bullets.push(new am4core.Circle());
		bullet.fill = am4core.color("#ff0000");
		bullet.propertyFields.fill = "color";
		bullet.strokeOpacity = 0;
		bullet.strokeWidth = 2;
		bullet.fillOpacity = 0.9;
		bullet.stroke = am4core.color("#ffffff");
		bullet.hiddenState.properties.opacity = 0;
		bullet.tooltipText = `[bold]{locationName}:[/]\n{locationAddress}[/]\n${
			languageFile[localStorage.getItem("language")]["22491"]
		}: {valueX.value}\n${
			languageFile[localStorage.getItem("language")]["10243"]
		}: {valueY.value}`;

		var outline = bubbleChart.plotContainer.createChild(am4core.Circle);
		outline.fillOpacity = 0;
		outline.strokeOpacity = 0.9;
		outline.stroke = am4core.color("#ff0000");
		outline.strokeWidth = 2;
		outline.hide(0);

		var blurFilter = new am4core.BlurFilter();
		outline.filters.push(blurFilter);

		bullet.events.on("over", function (event) {
			var target = event.target;
			outline.radius = target.pixelRadius + 2;
			outline.x = target.pixelX;
			outline.y = target.pixelY;
			outline.show();
		});

		bullet.events.on("out", function (event) {
			outline.hide();
		});

		var hoverState = bullet.states.create("hover");
		hoverState.properties.fillOpacity = 1;
		hoverState.properties.strokeOpacity = 1;

		series.heatRules.push({
			target: bullet,
			min: 2,
			max: 14,
			property: "radius",
		});

		bullet.adapter.add("tooltipY", function (tooltipY, target) {
			return -target.radius;
		});

		bubbleChart.cursor = new am4charts.XYCursor();
		bubbleChart.cursor.behavior = "zoomXY";
		bubbleChart.cursor.snapToSeries = series;

		bubbleChart.scrollbarX = new am4core.Scrollbar();
		bubbleChart.scrollbarY = new am4core.Scrollbar();
		bubbleChart.scrollbarX.disabled = !this.props.showChartZoom;
		bubbleChart.scrollbarY.disabled = !this.props.showChartZoom;
		bubbleChart.data = this.formatWaveData(this.props.waveGraphData);

		// Legend
		bubbleChart.legend = new am4charts.Legend();
		// bubbleChart.legend.position = "right";
		// bubbleChart.legend.disabled = this.props.showBrandChart;
		var marker = bubbleChart.legend.markers.template.children.getIndex(0);
		marker.cornerRadius(12, 12, 12, 12);
		// bubbleChart.logo.disabled = true;
		this.bubbleChart = bubbleChart;
		this.legendCustomization();
	}
	componentDidUpdate(oldProps) {
		this.bubbleChart.scrollbarX.disabled = !this.props.showChartZoom;
		this.bubbleChart.scrollbarY.disabled = !this.props.showChartZoom;
		this.legendCustomization();
		if (oldProps.waveGraphData !== this.props.waveGraphData) {
			this.bubbleChart.data = this.formatWaveData(this.props.waveGraphData);
		}
	}
	legendCustomization = () => {
		if (!this.props.showBrandChart) {
			this.bubbleChart.legend.data = this.formatWaveData(
				this.props.waveGraphData,
			).map((i) => ({
				name: i.brandName,
				id: i.locationName,
				fill: i.color,
				visible: !i.hidden,
			}));
		} else {
			this.bubbleChart.legend.data = _.uniqBy(
				this.formatWaveData(this.props.waveGraphData),
				"brandName",
			).map((i) => ({
				name: i.brandName,
				id: i.brandName,
				fill: i.color,
				visible: !i.hidden,
			}));
		}
		this.bubbleChart.legend.itemContainers.template.events.on("up", (event) => {
			const id = event.target.dataItem.dataContext.id;
			// this.bubbleChart.data.find(i => i.brandName === id).hidden = !event.target.isActive;
			_.map(this.bubbleChart.data, (i, key) => {
				if (i.brandName === id) {
					this.bubbleChart.data[key].hidden = !event.target.isActive;
				}
			});
			this.bubbleChart.validateData();
		});
		this.bubbleChart.legend.itemContainers.template.events.on(
			"hit",
			(event) => {
				const id = event.target.dataItem.dataContext.id;
				// this.bubbleChart.data.find(i => i.brandName === id).hidden = !event.target.isActive;
				_.map(this.bubbleChart.data, (i, key) => {
					if (i.brandName === id) {
						this.bubbleChart.data[key].hidden = !event.target.isActive;
					}
				});
				this.bubbleChart.validateData();
			},
		);
	};
	// renderGraphWidth = () => {
	//     if (this.props.showBrandChart) {
	//         return "1070px";
	//     } else {
	//         return "830px";
	//     }

	render() {
		return (
			<div
				id="wavechart"
				style={{ width: "100%", height: "100%", margin: "0 auto" }}></div>
		);
	}
}
export default ReviewRatingWaveChart;
