import AppDispatcher from "../../../flux/dispatcher/dispatcher";
// import AppDispatcher from 'dispatcher/dispatcher';
import Constant from "../constant/Constants";
import restClient from "../../MainFrame/action/APIClient";
import LogoutConstant from "../../MainFrame/constants/Constants";
var _ = require("lodash");

var DateWiseHistoAction = function () {};

DateWiseHistoAction.prototype = {
	loadData: function (data) {
		data["filters"]["insightFilters"] = {
			metrics: [
				"ACTIONS_DRIVING_DIRECTIONS",
				"ACTIONS_PHONE",
				"ACTIONS_WEBSITE",
			],
		};
		data["granularity"] = "weekly";
		data["graphType"] = ["dayOfWeek"];
		restClient.getLocationInsightHistogram(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error,
					});
				} else {
					AppDispatcher.dispatch({
						actionType: Constant.DATE_WISE_HISTO,
						data: error,
					});
				}
			} else if (response != null) {
				response["status"] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.DATE_WISE_HISTO,
					data: response,
				});
			}
		});
	},
};

export default new DateWiseHistoAction();
