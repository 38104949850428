import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Amchartmenu } from "../../routers/Dashboard/children/ChartDownload";
import _ from "lodash";
import { reviewTranslationMap } from "../../../src/utils/reviewStarTranslation";

am4core.useTheme(am4themes_animated);

class ResponseTimeBarChart extends Component {
	componentDidMount() {
		this.responseTimeRender();
	}

	responseTimeRender() {
		var responseTimeBarChart = am4core.create(
			"responsetimegraph",
			am4charts.XYChart,
		);
		responseTimeBarChart.data = this.props.chartData;
		var categoryAxis = responseTimeBarChart.yAxes.push(
			new am4charts.CategoryAxis(),
		);
		categoryAxis.dataFields.category = "review";
		// categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 20;
		categoryAxis.tooltip.disabled = true;
		categoryAxis.renderer.grid.template.disabled = true;
		categoryAxis.renderer.line.strokeOpacity = 1;
		categoryAxis.renderer.line.strokeWidth = 1;
		categoryAxis.renderer.line.stroke = "#8c8c8c";
		categoryAxis.renderer.labels.template.enabled = true;
		// let label = categoryAxis.renderer.labels.template;
		// label.tooltipText = "{category}";

		//axis tick
		categoryAxis.renderer.ticks.template.disabled = false;
		categoryAxis.renderer.ticks.template.strokeOpacity = 1;
		categoryAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
		categoryAxis.renderer.ticks.template.strokeWidth = 2;
		categoryAxis.renderer.ticks.template.length = 7;
		categoryAxis.renderer.labels.template.adapter.add(
			"textOutput",
			function (text, target) {
				return reviewTranslationMap[text];
			},
		);

		var valueAxis = responseTimeBarChart.xAxes.push(new am4charts.ValueAxis());
		valueAxis.renderer.grid.template.disabled = true;
		valueAxis.renderer.minWidth = 50;
		valueAxis.min = 0;
		valueAxis.cursorTooltipEnabled = false;
		valueAxis.renderer.line.strokeOpacity = 1;
		valueAxis.renderer.line.strokeWidth = 2;
		valueAxis.renderer.line.stroke = "#8c8c8c";
		valueAxis.renderer.opposite = false;
		valueAxis.renderer.minGridDistance = 20;
		valueAxis.max = 100;

		//axis tick
		valueAxis.renderer.ticks.template.disabled = false;
		valueAxis.renderer.ticks.template.strokeOpacity = 1;
		valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
		valueAxis.renderer.ticks.template.strokeWidth = 2;
		valueAxis.renderer.ticks.template.length = 7;

		// Create series

		function createSeries(field, name, color) {
			var series = responseTimeBarChart.series.push(
				new am4charts.ColumnSeries(),
			);
			series.dataFields.valueX = field;
			series.dataFields.categoryY = "review";
			series.tooltipText = "{name}: {valueX.formatNumber('#.0')}[/]%";
			series.stacked = true;
			series.fill = am4core.color(color);
			series.stroke = am4core.color(color);
			series.name = name;
		}
		_.map(this.props.colourMap, (item, key) => {
			if (item.shouldEnableLegend) {
				createSeries(key, item.label, item.color);
			}
		});

		// Cursor
		responseTimeBarChart.cursor = new am4charts.XYCursor();

		//download
		responseTimeBarChart.exporting.menu = new am4core.ExportMenu();
		responseTimeBarChart.exporting.menu.items = _.cloneDeep(Amchartmenu);
		responseTimeBarChart.exporting.timeoutDelay = 8000;
		responseTimeBarChart.exporting.dataFields = this.props.downloadHeader;
		responseTimeBarChart.exporting.filePrefix = "Response Distribution";

		// Add legend
		responseTimeBarChart.legend = new am4charts.Legend();
		// responseTimeBarChart.logo.disabled = true;
		this.responseTimeBarChart = responseTimeBarChart;
	}
	componentDidUpdate(oldProps) {
		if (
			!_.isEqual(
				oldProps.ResponseReferenceHistogram,
				this.props.ResponseReferenceHistogram,
			)
		) {
			this.responseTimeBarChart.data = this.props.chartData;
		}
	}
	componentWillUnmount() {
		if (this.responseTimeBarChart) {
			this.responseTimeBarChart.dispose();
		}
	}
	render() {
		return (
			<div
				id={"responsetimegraph"}
				style={{ width: "100%", height: "100%" }}></div>
		);
	}
}
export default ResponseTimeBarChart;
