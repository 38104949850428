import AppDispatcher from "../../../flux/dispatcher/dispatcher";
import Constant from "../constant/Constants";
import restClient from "../../MainFrame/action/APIClient";
import LogoutConstant from "../../MainFrame/constants/Constants";

var GetReviewsOverViewAction = function () {};

GetReviewsOverViewAction.prototype = {
	getReviews: function (data) {
		restClient.getReviewsOverview(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error,
					});
				} else {
					AppDispatcher.dispatch({
						actionType: Constant.GET_REVIEWS_OVERVIEW,
						data: error,
					});
				}
			} else {
				response["status"] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.GET_REVIEWS_OVERVIEW,
					data: response,
				});
			}
		});
	},
};

export default new GetReviewsOverViewAction();
